
@import url('https://fonts.googleapis.com/css?family=Josefin+Sans');

@import url('https://fonts.googleapis.com/css?family=Rubik');

html,body,#root,.App,.AppContainer{
  width: 100%;
  height: 100%;
  margin:0px;
  padding: 0px;
}


/** Logo color: R 3,G 169,B 245 **/

body {
  font-family: 'Rubik' !important;
}

.headerContainer{
  background: white;
}

.menu_form .top_row{
  padding-top: 0.7em;
  padding-bottom: 0.7em;
}

.menu_form .top_row .control-label,
.menu_form .bottom_row .control-label{
  text-align: center;
  font-size: 1.1em;
}

.menu_form .bottom_row{

}

.qrCodeDownloadImages{
  display: block;
  border: 2px solid #eee;
  width: auto;
  display: inline-block;
}
.qrCodeDownloadImages:hover{
  display: block;
  border: 2px solid #0db6e0;
  width: auto;
  display: inline-block;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
}

.loadingContainer{
  width: 100%;
  height: 100%;
}

.productsTableBody{
  padding: 0.5em;
  background: #fff;
}


.qrcodelabel{
  color: #1f66a4 !important;;
  font-weight:bold;
  letter-spacing:2px;
  padding:0.5em;
  padding-top:0.2em;
  padding-bottom:0.2em;
  display:inline-block;
  background: #fff;
  border: 2px solid #ddd;

}

.qrcodelabel:hover{
  border: 2px solid #02a8f2;
  width: auto;
  display: inline-block;
}

.manuLabelTitle{
  margin-right:0.5em;
  float: left;
}



.logoutLink{
  margin-left: 1em;
  text-decoration: none;
  color: #1f66a4;
}

.manuLabel{
  font-weight: bold;
  letter-spacing: 1px;
}

.productsTableBody table{
  border-collapse: inherit;
}

.productsTableBody thead tr{
  color: #01155c;
  font-weight: bold;
}

.pagenumbers{
  width: 80px !important;
  margin-left: 0.5em;
  display: inline !important;
  text-align: center;
}

.menu_form button.pageswitch{
  background: #fff !important;;
  border-radius: 1em;
  padding-left: 0.5em;
  padding-right: 0.5em;
  padding-bottom: 0.2em;
  font-weight: bold;
  border: 1px solid #ddd;
}

.menu_form button.pageswitch:hover{
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}


.iconbox{
  display: inline-block;
  float: left;
  width: auto !important;
  margin-right: 0.5em;
  margin-bottom: 0.5em;
  min-width: 55px;
  padding: 0.2em;
  font-weight: bold;
  float: right;
  text-align: right;
}
.imagebox{
  display: inline-block;
  float: left;
  width: auto !important;
  margin-right: 1em;
  margin-bottom: 1em;

}

.imagebox img{
  border-radius: 0.5em;
  border: 1px solid #01155c;
}
.imagebox img:hover{
  border: 1px solid #02a8f2;
}

.productsTableBody tbody{
  font-size: 0.9em;
}
.productsTableBody tbody button{
  font-size: 0.9em;
}

.mainHeadline{
  color: white;
  font-size: 1.5em;
}


.qrcodeImageDownloadDropdown button{
  background: none !important;
  padding: 0;
  margin: 0;
  border: none;
}
.qrcodeImageDownloadDropdown button.dropdown-toggle::after
{
  display: none;
}


.header_logo{
  max-height:3em;
  float:left;
}

table{
  border:none;
  border-collapse: collapse;
  border-collapse: collapse !important;
}



table thead tr:nth-child(1) td{
  padding: 1em;
}


table{
  padding: 0 !important;
  margin: 0 !important;
  border-collapse: collapse;
  border: none;
  text-align: left;
}


table tbody tr td:nth-last-child(1){
  padding: 0.5em;
  text-align: left;
}
table tbody tr td{
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  padding: 0.5em;
  border-collapse: collapse;
  border: none;
}



.App .container{
  background: #fff;
}

table tr:nth-child(odd) td{
}
table thead td{
  background: #02a8f2;
  color: white;
}
.productsTableBody{
  padding: 0em !important;
  margin-bottom: 2em;
}

table.detailsTable td{
  padding: 0.5em;
  width: 100%;
}
table.detailsTable td:nth-child(2){
  text-align: center;
}

.loadingContainer{
  width: 100%;
  height: 200px !important;
  margin-top:1em;
  content: "Lade Inhalte";
  border-radius:1em;
  background-position:center center !important;
  background-repeat: no-repeat !important;
}

.mobileProductArtikeltext span{

}

span.abgelaufenIcon{
  width: 1em;
  height:1em;
  display: block;
  border-radius: 1em;
  border: 2px solid white;
}

span.abgelaufenIcon.aktiv{
  background: green;
}
span.abgelaufenIcon.abgelaufen{
  background: red;
}

.bruttoPriceValField input{
  background: white !important;
  color: #222 !important;
}

/* responsive, form small screens, use 13px font size */
@media (max-width: 970px) {
  .productsTableBody.desktop{
    display: none;
  }
  input#searchString{
    width:calc(100% - 3.4em) !important;
    display: inline-block;
    padding-right:0em;
  }
  button.searchButton{
    width:3em !important;
  }
  .headerContainer .manuLabelTitle{
    display: none;
  }
  select#pagination_max_entries{
    width:4em !important;
  }
}

@media (max-width: 1320px) {
  .headerContainer{
    padding:1em;
  }
}
/* responsive, form small screens, use 13px font size */
@media (min-width: 971px) {
  .productsTableBody.mobile{
    display: none;
  }
  input#searchString{
    width:calc(100% - 3.4em) !important;
    display: inline-block;
  }
  button.searchButton{
    width:3em !important;
  }
}